import { useOutletContext } from "react-router-dom"
import { MyAccountProps } from "./MyAccount"
import LinkButton from "../General/LinkButton"
import { useState } from "react"
import JarTypeModal from "./JarTypeModal"

const TypeSelect = () => {

  const {setDocumentTitle, setSubTitle} = useOutletContext<MyAccountProps>()
  setDocumentTitle("Create a Jar")
  setSubTitle("What are you collecting for?")

  const [showJarTypeInfoModal, setShowJarTypeInfoModal] = useState<boolean>(false)

  return <>
    {showJarTypeInfoModal === true ?
      <JarTypeModal onClose={() => setShowJarTypeInfoModal(false)}/>
    : null }

    <div className="text-center my-auto p-4 md:p-0">
      <LinkButton type="link" url="gift" backgroundColour="my-olive">Gifting Collection</LinkButton>
      <div className="relative flex py-5 items-center">
        <div className="flex-grow border-t border-my-olive"></div>
        <span className="flex-shrink mx-4 text-my-olive">Or</span>
        <div className="flex-grow border-t border-my-olive"></div>
      </div>
      <LinkButton type="link" url="general" backgroundColour="my-olive">General Collection</LinkButton>
    </div>

    <button type="button" onClick={() => setShowJarTypeInfoModal(true)} className="underline py-4">
      Which jar type am I collecting for?
    </button>
  </>
}

export default TypeSelect