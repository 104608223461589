import { useEffect, useState } from "react";
import { useSigninCheck } from "reactfire";
import { User } from "firebase/auth";
import LinkButton from "../../General/LinkButton";
import {ReactComponent as ApplePay} from "../../Icons/ApplePay.svg";
import {ReactComponent as GooglePay} from "../../Icons/GooglePay.svg";
import {ReactComponent as MasterCard} from "../../Icons/MasterCard.svg";
import Visa from "../../Icons/Visa.png";

const Header = () => {

  const [user, setUser] = useState<User | null>(null)

  const { status, data: signInCheckResult } = useSigninCheck();

  /*
  I think there's a bug here, more a race condition, where when a user logs in
  their auth status isn't set and we can't render the my-account page
  */
  useEffect(() => {
    if (
      status !== 'loading' && status !== 'error' &&
      signInCheckResult.signedIn === true &&
      signInCheckResult.user?.isAnonymous === false
    ) {
      setUser(signInCheckResult.user)
    } else {
      setUser(null)
    }
  }, [signInCheckResult, status])

  return (
    <div className="header min-h-fit h-screen bg-home-header bg-no-repeat bg-top-left md:bg-top bg-cover xl:bg-cover text-white">

      <div className="title-text content-end md:content-end grid grid-columns-1 place-content-center h-3/6">
        <div>
          <h1 className="text-5xl sm:text-6xl font-poppins font-bold capitalize inline-block">Flossi...&nbsp;</h1>
          <h2 className="text-5xl sm:text-6xl font-poppins font-bold inline-block">it's effortless</h2>
        </div>
        <h3 className="text-2xl uppercase font-poppins">Collecting money online made easy</h3>
      </div>

      <div className="buttons content-center max-w-screen-md gap-4 grid grid-cols-1 sm:grid-cols-2 h-2/6 px-10 md:mx-auto">
        <LinkButton url="/create-jar" type="link">Create Jar</LinkButton>
        <LinkButton url="/jars" type="link">{!user ? "Login" : "My Jars"}</LinkButton>
      </div>

      <div className="grid grid-cols-4 max-w-screen-md sm:max-w-lg mx-auto h-1/6 px-10">
        <div className="my-auto text-left">
          <ApplePay className="w-12 my-auto p-0"/>
        </div>

        <div className="my-auto">
          <GooglePay className="w-20 my-auto -ml-2 sm:-ml-0 p-0" />
        </div>

        <div className="my-auto">
          <MasterCard className="w-14 m-auto p-0" />
        </div>

        <div className="my-auto flex justify-end">
          <img src={Visa} className="w-16 my-auto p-0" alt="VISA" />
        </div>
        <p className="col-span-7 font-beloved">
          UK Bank Account Required
        </p>
      </div>
    </div>
  )
}

export default Header