// import {ReactComponent as ApplePay} from "../Icons/ApplePay.svg";
import {ReactComponent as MasterCard} from "../Icons/MasterCard.svg";
import Visa from "../Icons/Visa.png";
import GPay from "../Icons/GPay.png";
import ApplePay from "../Icons/ApplePay.png";

const ContributionPagePaymentLogos = () => {
  return <div className="grid grid-cols-4 max-w-xs mx-auto px-10 pt-1">
    <div className="my-auto text-left">
      <img src={ApplePay} className="h-6 my-auto" alt="Apple Pay"/>
    </div>

    <div className="my-auto">
      <img src={GPay} className="h-6 pl-1" alt="Google Pay" />
    </div>

    <div className="my-auto">
      <MasterCard className="h-8 pl-2" />
    </div>

    <div className="my-auto flex justify-end">
      <img src={Visa} className="h-4 my-auto" alt="VISA" />
    </div>
  </div>
}

export default ContributionPagePaymentLogos