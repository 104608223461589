import { useState } from "react"
import Modal from "../General/Modal"
import {ReactComponent as ChevronDown} from "../Icons/chevron-down.svg"
import {ReactComponent as ChevronUp} from "../Icons/chevron-up.svg"

const JarTypeModal = ({onClose}: {onClose: () => void}) => {
  const [sectionOnShow, setSectionOnShow] = useState<false | number>(false)
  const [typeOnShow, setTypeOnShow] = useState<"gifting" | "general">("gifting")
  const baseClass = "rounded-md p-2 break-none font-bold text-center"
  const activeClass = `${baseClass} bg-white`
  const inactiveClass = `${baseClass} ext-gray-400 hover:text-my-grey hover:bg-gray-300`

  return<Modal
    closeFunction={onClose}
    title="Jar Type"
  >
    <div className="space-y-2 text-left text-sm">
      <div className="my-2 md:w-2/3 mx-auto grid grid-cols-2 gap-1 bg-gray-200 p-1 rounded-[calc(theme(borderRadius.md)+theme(spacing.1))]">
        <button
          type="button"
          className={typeOnShow === "gifting" ? activeClass : inactiveClass}
          onClick={() => {
            setTypeOnShow("gifting")
            setSectionOnShow(false)
          }}
        >
          Gifting Collections
        </button>

        <button
          type="button"
          className={typeOnShow === "general" ? activeClass : inactiveClass}
          onClick={() => {
            setTypeOnShow("general")
            setSectionOnShow(false)
          }}
        >
          General Collections
        </button>
      </div>

      <div className={`${typeOnShow === "gifting" ? "block" : "hidden"} space-y-2`}>

        <p className="text-md">These are used to <span className="font-bold">collect money</span> for <span className="font-bold">gift</span> purposes only. This type of collection can <span className="font-bold">be for yourself</span> or collected <span className="font-bold">on behalf of someone else</span>.</p>
        <table className="text-xs">
          <tr>
            <th className="font-normal bg-my-olive text-white p-1 border border-my-grey border-b-0">
              Typical Examples
            </th>
          </tr>
          <tr>
            <td className="p-1 border border-my-grey">
              Wedding gifts, Birthday gifts, Leaving gifts, Baptism gifts and Christmas gifts.
            </td>
          </tr>
          <tr>
            <th className="font-normal bg-my-olive text-white p-1 border border-my-grey">
              How The Fees Work
            </th>
          </tr>
          <tr>
            <td className="p-1 border border-my-grey">
              For Gifting Collections, the small fees are paid for by the contributor, ensuring the giftee receives 100% of the intended gift amount.
            </td>
          </tr>
        </table>
        <p className="text-red-500">Note: Charity Donations are categorised as General Collections.</p>

        <div>
          <h3 className="text-left cursor-pointer text-my-olive underline"
            onClick={() => {
              if (sectionOnShow === 1) return setSectionOnShow(false)
              setSectionOnShow(1)
            }}
          >
            Gifting Example 1 (Wedding Gift)
            <span className="inline-block align-middle">
              {sectionOnShow === 1 ?
                <ChevronUp />
              :
                <ChevronDown />
              }
            </span>
          </h3>
          <div className={`${sectionOnShow !== 1 ? "hidden" : ""} space-y-2`}>
            <p>You create a collection jar for your wedding to provide your guests with the convenience of cashless gifting.</p>
            <p><span className="font-bold">Flossi Tip:</span> Include the jar QR code within your wedding invitations so your guests can easily make their contributions before the wedding day.</p>
          </div>
        </div>

        <h3 className="text-left cursor-pointer text-my-olive underline"
          onClick={() => {
            if (sectionOnShow === 2) return setSectionOnShow(false)
            setSectionOnShow(2)
          }}
        >
          Gifting Example 2 (Leaving Gift for a Colleague)
          <span className="inline-block align-middle">
            {sectionOnShow === 2 ?
              <ChevronUp />
            :
              <ChevronDown />
            }
          </span>
        </h3>
        <div className={`${sectionOnShow !== 2 ? "hidden" : ""} space-y-2`}>
          <p>You are collecting money as a gift for a colleague who is leaving their role.</p>
          <p><span className="font-bold">Flossi Tip</span>: Send the jar link to colleagues via Email/WhatsApp allowing them to contribute when they get a spare minute.</p>
        </div>
      </div>

      <div className={`${typeOnShow === "general" ? "block" : "hidden"} space-y-2`}>

        <p className="text-md">These are used to <span className="font-bold">collect money</span> for <span className="font-bold">non-gift</span> purposes. This type of collection is <span className="font-bold">for yourself</span>, your <span className="font-bold">non-profit organisation</span> or your <span className="font-bold">business</span>.</p>
        <table className="text-xs">
          <tr>
          <th className="font-normal bg-my-olive text-white p-1 border border-my-grey border-b-0">
              Typical Examples
            </th>
          </tr>
          <tr>
          <td className="p-1 border border-my-grey">
              Businesses, Buskers, Pop Up Shops, Honesty Boxes, Collections between Friends, Amateur Sports Clubs, Events and Non-Profits
            </td>
          </tr>
          <tr>
          <th className="font-normal bg-my-olive text-white p-1 border border-my-grey border-b-0">
              How The Fees Work
            </th>
          </tr>
          <tr>
          <td className="p-1 border border-my-grey">
              For General Collections, the jar owner chooses if they or the payer will pay the small fees.
            </td>
          </tr>
        </table>

        <h3 className="text-left cursor-pointer text-my-olive underline"
          onClick={() => {
            if (sectionOnShow === 3) return setSectionOnShow(false)
            setSectionOnShow(3)
          }}
        >
          General Collection Example 1 (Busker/Street Performer)
          <span className="inline-block align-middle">
            {sectionOnShow === 3 ?
              <ChevronUp />
            :
              <ChevronDown />
            }
          </span>
        </h3>
        <div className={`${sectionOnShow !== 3 ? "hidden" : ""} space-y-2`}>
          <p>You are a street performer and find you are missing out on a lot of money as you currently only accept cash payments. You create a collection jar to simplify your collection process.</p>
          <p><span className="font-bold">Flossi Tip:</span> Print off your jar QR code to enable anyone to easily make a quick card payment during your performance.</p>
        </div>

        <h3 className="text-left cursor-pointer text-my-olive underline"
          onClick={() => {
            if (sectionOnShow === 4) return setSectionOnShow(false)
            setSectionOnShow(4)
          }}
        >
          General Collection Example 2 (Collection Between Friends)
          <span className="inline-block align-middle">
            {sectionOnShow === 4 ?
              <ChevronUp />
            :
              <ChevronDown />
            }
          </span>
        </h3>
        <div className={`${sectionOnShow !== 4 ? "hidden" : ""} space-y-2`}>
          <p>You've been given the responsibility to collect the money for a trip away for you and your friends. You want to use Flossi to make your life easier.</p>
          <p><span className="font-bold">Flossi Tip:</span> Make use of the 'bill' function which simply sends fixed amount payment links to your friends, allowing for effortless tracking of payments. You can also send polite payment reminders to those friends who haven't yet paid.</p>
        </div>
</div>
    </div>
  </Modal>
}

export default JarTypeModal