import { Timestamp, doc, getDoc } from "firebase/firestore"
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form"
import { Link, useSearchParams } from "react-router-dom";
import { Event, FirestoreGiftConverter, PaymentStatus, GiftContribution, ContributionTypes } from "../../FirestoreConverters";
import { useAnalytics, useFirestore, useFunctions } from "reactfire";
import { logEvent } from "firebase/analytics";
import LinkButton from "../General/LinkButton";
import { calculateGiftAmounts, saveGift } from "../../services/Gift";
import { User } from "firebase/auth";
import ContributionPagePaymentLogos from "./ContributionPagePaymentLogos";

type FormValues = {
  amount: string;
  customAmount: string;
  message?: string;
  cardholderFName: string;
  cardholderLName: string;
  email: string;
}

const GiftContributionForm = ({event, user}: {event: Event, user: User}) => {
  const analytics = useAnalytics()
  const firestore = useFirestore()
  const [searchParams] = useSearchParams()
  const functions = useFunctions()
  const [errorMessage, setErrorMessage]= useState<boolean | string>(false)

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    setIsLoading(true)
    setErrorMessage(false)

    const gift: GiftContribution = {
      message: data.message !== undefined ? data.message.trim() : undefined,
      giftAmount: giftAmount,
      feeAmount: feeAmount,
      totalAmount: totalAmount,
      email: data.email.trim(),
      cardholderFName: data.cardholderFName.trim(),
      cardholderLName: data.cardholderLName.trim(),
      giftedAt: new Timestamp(Date.now()/1000, 0),
      uid: user.uid,
      type: ContributionTypes.GIFT,
      status: PaymentStatus.PENDING
    }
    /**
     * 1. Construct Gift
     * 2. Save Gift in Firestore
     * 4. Update gift with request
     * 3. Create payment job in cashflows
     * 4. Update gift with payment response
     * 5. Popup/redirect hosted payment page
     */

    try {
      const savedGift = await saveGift(firestore, functions, event, gift)
      logEvent(analytics, "begin_checkout", {
        currency: "GBP",
        value: gift.totalAmount,
        items: [
          {
            item_name: "Gift Amount",
            price: gift.giftAmount
          },
          {
            item_name: "Fee Amount",
            price: gift.feeAmount
          }
        ]
      })
      const giftData = savedGift.data()
      if (giftData === undefined || giftData.paymentJob === undefined) {
        throw new Error("Failed to initiate payment")
      }
      window.location.href = giftData.paymentJob.links.action.url
    } catch (e: any) {
      console.log(e)
      setErrorMessage("Error, please try again")
      logEvent(analytics, "exception", {
        description: e.toString()
      })
      setIsLoading(false)
    }
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    resetField,
    setValue,
    setFocus
  } = useForm<FormValues>({
    mode: 'onChange',
    reValidateMode: 'onChange'
  });

  const formData = watch()

  const [giftAmount, setGiftAmount] = useState<number>(0)
  const [feeAmount, setFeeAmount] = useState<number>(0)
  const [totalAmount, setTotalAmount] = useState<number>(0)
  const [customAmountRequired, setCustomAmountRequired] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const select = parseFloat(formData.amount)
    let amount = parseFloat(formData.amount) || parseFloat(formData.customAmount) || 0

    if (!isNaN(select) && select > 0 && customAmountRequired) {
      setCustomAmountRequired(false)
      resetField("customAmount")
    }

    if (select === 0) {
      setCustomAmountRequired(true)
    }

    const {
      feeAmount,
      giftAmount,
      totalAmount
    } = calculateGiftAmounts(event, amount)

    if (amount < 0) {
      setGiftAmount(0)
      setFeeAmount(0)
      setTotalAmount(0)
    } else{
      setGiftAmount(giftAmount)
      setFeeAmount(feeAmount)
      setTotalAmount(totalAmount)
    }

    logEvent(analytics, "select_item", {
      items: [
        {
          item_name: "Gift Amount",
          price: giftAmount
        },
        {
          item_name: "Fee Amount",
          price: feeAmount
        }
      ]
    })
  }, [
    formData,
    event,
    customAmountRequired,
    event.feeIncluded,
    setGiftAmount,
    setFeeAmount,
    setTotalAmount,
    setCustomAmountRequired,
    resetField,
    analytics
  ])

  useEffect(() => {

    const ordNum = searchParams.get("ordernumber")
    if (ordNum === null) return
    const [eventId, giftId] = ordNum.split("/")

    setIsLoading(true)
    const giftRef = doc(firestore, `events/${eventId}/gifts/${giftId}`)
      .withConverter(FirestoreGiftConverter)

    getDoc(giftRef)
      .then((g) => {
        const d = g.data()

        if (d === undefined || d.status === PaymentStatus.PAID) return

        d.email !== undefined && setValue("email", d.email)
        d.cardholderFName !== undefined && setValue("cardholderFName", d.cardholderFName)
        d.cardholderLName !== undefined && setValue("cardholderLName", d.cardholderLName)

        setValue("message", d.message || "")

        // How do I figure out custom vs predefined? :/
        if ([5, 10, 20, 30, 50, 100].includes(d.giftAmount)) {
          setValue("amount", d.giftAmount.toString())
        } else {
          setValue("customAmount", d.giftAmount.toString())
        }

        // Don't need to set it as state if we set form values
        //setGiftData(g.data())
        //console.log(g.data())
        setIsLoading(false)
      })
      .catch((e) => {
        console.log(e)
        setIsLoading(false)
      })
  }, [
    firestore,
    searchParams,
    event,
    setValue
  ])

  // This should check the 0 amount and set custom to required
  const focusCustomAmount = () => {
    setCustomAmountRequired(true)
    setValue("amount", "custom")
    setFocus("customAmount")
  }

  const giftClass = "block pt-8 pb-6 font-bold text-3xl text-my-grey border border-my-grey rounded-lg border-2 hover:border-my-olive hover:bg-my-olive hover:text-white  peer-checked:text-white peer-checked:bg-my-orange peer-checked:border-my-orange cursor-pointer"

  return (
    <div>
      <p className="text-lg md:text-2xl uppercase font-beloved p-4 md:p-16 break-words">{event.message}</p>
      {!event.hasEnded &&
      <div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <fieldset disabled={isLoading}>
          <div className="grid grid-cols-2 md:grid-cols-3 gap-4 max-w-xl mx-auto font-beloved">
            <div>
              <input
                {
                  ...register(
                    "amount",
                    {
                      required: {
                        value: !customAmountRequired,
                        message: "Please select an amount"
                      },
                      valueAsNumber: true
                    }
                  )
                }
                type="radio" value="5" id="amt-5"
                className="hidden peer"
              />
              <label htmlFor="amt-5" className={giftClass}>
                &pound;5
              </label>
            </div>
            <div>
              <input
                {
                  ...register(
                    "amount",
                    {
                      required: {
                        value: !customAmountRequired,
                        message: "Please select an amount"
                      },
                      valueAsNumber: true
                    }
                  )
                }
                type="radio" value="10" id="amt-10"
                className="hidden peer"
              />
              <label htmlFor="amt-10" className={giftClass}>
                &pound;10
              </label>
            </div>
            <div>
              <input
                {
                  ...register(
                    "amount",
                    {
                      required: {
                        value: !customAmountRequired,
                        message: "Please select an amount"
                      },
                      valueAsNumber: true
                    }
                  )
                }
                type="radio" value="20" id="amt-20"
                className="hidden peer"
              />
              <label htmlFor="amt-20" className={giftClass}>
                &pound;20
              </label>
            </div>
            <div>
              <input
                {
                  ...register(
                    "amount",
                    {
                      required: {
                        value: !customAmountRequired,
                        message: "Please select an amount"
                      },
                      valueAsNumber: true
                    }
                  )
                }
                type="radio" value="30" id="amt-30"
                className="hidden peer"
              />
              <label htmlFor="amt-30" className={giftClass}>
                &pound;30
              </label>
            </div>
            <div>
              <input
                {
                  ...register(
                    "amount",
                    {
                      required: {
                        value: !customAmountRequired,
                        message: "Please select an amount"
                      },
                      valueAsNumber: true
                    }
                  )
                }
                type="radio" value="50" id="amt-50"
                className="hidden peer"
              />
              <label htmlFor="amt-50" className={giftClass}>
                &pound;50
              </label>
            </div>
            <div>
              <input
                {
                  ...register(
                    "amount",
                    {
                      required: {
                        value: !customAmountRequired,
                        message: "Please select an amount"
                      },
                      valueAsNumber: true
                    }
                  )
                } type="radio" value="100" id="amt-100"
                className="hidden peer"
              />
              <label htmlFor="amt-100" className={giftClass}>
                &pound;100
              </label>
            </div>
            <div className="col-span-2 md:col-span-3">
              <input
                {
                  ...register(
                    "amount",
                    {
                      required: {
                        value: !customAmountRequired,
                        message: "Please select an amount"
                      },
                      valueAsNumber: true
                    }
                  )
                } type="radio" value="custom" id="amt-custom"
                className="hidden peer"

                onClick={focusCustomAmount}
              />
              <label htmlFor="amt-custom" className={`pt-3 pb-0 font-bold text-3xl text-my-grey border-my-grey rounded-lg border-2 hover:border-my-olive hover:bg-my-olive hover:text-white  peer-checked:text-white peer-checked:bg-my-orange peer-checked:border-my-orange group flex md:w-2/3 md:mx-auto cursor-pointer`}>
                <span className="my-auto px-2">&pound;</span>
                <input
                  type="number"
                  step="0.01"
                  min={0}
                  max={event.organiser.id === "nBJ23V5SnqU2duBEqvJsVdz8v9V2" ? 50000 : 3000}
                  placeholder={customAmountRequired ? "" : "Custom"}
                  {
                    ...register(
                      "customAmount",
                      {
                        required: {
                          value: customAmountRequired,
                          message: "Please enter an amount"
                        },
                        valueAsNumber: false,
                        pattern: {
                          value: /^[0-9]\d{0,5}(\.\d{2})?$/g,
                          message: "Invalid amount specified"
                        },
                        max: {
                          value: event.organiser.id === "nBJ23V5SnqU2duBEqvJsVdz8v9V2" ? 50000 : 3000,
                          message: "The maximum amount is £3,000"
                        },
                        min: {
                          value: event.feeIncluded === true ? 0.5 : 0.01,
                          message: event.feeIncluded === true ? "The minimum value is 50p" : "Please enter a value greater than 0"
                        },
                        setValueAs: (v) => {
                          const d = parseFloat(v).toFixed(2)
                          return d
                        }
                      }
                    )
                  }
                  onFocus={focusCustomAmount}
                  className={`w-10/12 md:w-11/12 h-20 text-center caret-white outline-none placeholder:text-my-grey focus:placeholder:text-white bg-transparent my-0 bg-none group-hover:placeholder:text-white cursor-pointer ${customAmountRequired ? "text-white placeholder:text-white" : ""}`}
                  pattern="\d{1-4}(\.d{2})"
                  inputMode="decimal"
                />
              </label>
            </div>
            <div className="col-span-2 md:col-span-3 font-poppins text-red-600">
              {errors.amount && <p>{errors.amount.message}</p>}
              {errors.customAmount && <p>{errors.customAmount.message}</p>}
            </div>
          </div>

          <div>
            <ul>
              {event.feeIncluded === false ?
                <>
                  <li>
                    {giftAmount < 2 ?
                      `Convenience Fee: £${feeAmount.toFixed(2)}`
                    :
                      `Fees [1% Service + 20p Convenience]: £${feeAmount.toFixed(2)}`
                    }</li>
                  <li>Organiser Gets: &pound;{(totalAmount-feeAmount).toFixed(2)}</li>
                </>
              :
                null
              }
              <li><b>Total Cost: &pound;{totalAmount.toFixed(2)}</b></li>
            </ul>
          </div>

          <div className="max-w-lg mx-auto text-left">
            <label className="block w-full font-bold uppercase py-2">
              Your message (optional):
            </label>
            <textarea {
              ...register(
                "message",
              )
            }
            className="border p-2 w-full outline-my-orange" />
            <p className="font-poppins text-red-600">{errors.message && errors.message.message}</p>
          </div>
          <div className="max-w-lg mx-auto text-left">
            <label className="block w-full font-bold uppercase py-2">
              Your Full Name:
            </label>
            <div className="grid grid-cols-2 gap-2">
              <input
                type="text"
                placeholder="First"
                {
                  ...register(
                    "cardholderFName",
                    {
                      required: "Please enter your first name as it appears on your payment card",
                      maxLength: 80
                    }
                  )
                }
                className="border p-2 outline-my-orange capitalize"
              />
              <input
                type="text"
                placeholder="Last"
                {
                  ...register(
                    "cardholderLName",
                    {
                      required: "Please enter your last name as it appears on your payment card",
                      maxLength: 80
                    }
                  )
                }
                className="border p-2 outline-my-orange capitalize"
              />
            </div>
            <p className="font-poppins text-red-600 text-center">{errors.cardholderFName && errors.cardholderFName.message}</p>
            <p className="font-poppins text-red-600 text-center">{errors.cardholderLName && errors.cardholderLName.message}</p>
          </div>
          <div className="max-w-lg mx-auto text-left mb-2">
            <label className="block w-full font-bold uppercase py-2">
              Your email address:
            </label>
            <input
              type="email"
              placeholder="Email"
              {
                ...register(
                  "email",
                  {
                    required: "Please enter your email",
                    pattern: {
                      value: /^\S+@\S+/i,
                      message: "Please enter a valid email"
                    }
                  }
                )
              }
              className="border p-2 w-full outline-my-orange"
            />
            <p className="font-poppins text-red-600">{errors.email && errors.email.message}</p>
          </div>

          {errorMessage !== false ?
          <div className="font-poppins text-red-600 border border-red-300 bg-red-100 inline-block px-10 py-4 mt-2">
            {errorMessage}
          </div> : null}

          <div className="mt-4">
            {/* <button type="submit" className="inline-block border uppercase text-center hover:border-my-grey border-solid bg-my-orange hover:bg-white font-bold text-white hover:text-my-grey w-full py-4 max-w-xs outline-my-orange"> */}
            <LinkButton
              type="submit"
              disabled={isLoading}
            >
              {isLoading === true ?
                <>
                  <div className="relative">Processing
                  <svg className="animate-spin h-6 w-6 absolute left-8 inline" viewBox="0 0 24 24">
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  </div>
                </>
              : <>
              Go to payment</>}
            </LinkButton>
            {/* </button> */}
            <p className="text-xs max-w-xs mx-auto pt-1">By proceeding to payment you are agreeing to the <Link className="text-my-orange font-bold hover:underline" to="/terms">Terms & Conditions</Link> and <Link className="text-my-orange font-bold hover:underline"  to="/privacy">Privacy Policy</Link>.</p>
            <ContributionPagePaymentLogos />
          </div>
        </fieldset>
      </form>
    </div>}
  </div>
  )
}

export default GiftContributionForm